<template>
  <span class="integral-type-select">
    <el-select v-model="selectType" placeholder="请选择积分类别或行为" size="small" @change="typeChange">
      <el-option label="积分类别" :value="0"> </el-option>
      <el-option label="积分行为" :value="1"> </el-option>
    </el-select>

    <el-select v-model="selectValue" placeholder="请选择" size="small">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
    </el-select>
  </span>
</template>

<script>
import { getIntegralCategoryList } from '@/api/integral'

export default {
  props: {
    type: {
      type: [Number, String],
      default: 0,
    },
    val: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      options: [],
      selectValue: '',
      selectType: 0,
    }
  },
  watch: {
    val(newVal) {
      this.selectValue = newVal || ''
    },
    selectValue(newVal) {
      this.$emit('update:val', newVal)
    },
    type(newVal) {
      this.selectType = newVal || ''
    },
    selectType(newVal) {
      this.$emit('update:type', newVal)
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getIntegralCategoryList({ data: { isDisable: 0, tyIntegralCategoryType: this.selectType } })
        .then((res) => {
          this.options = (res.data || []).map((el) => ({
            label: el.tyIntegralCategoryName,
            value: el.tyIntegralCategoryId,
          }))
        })
        .catch((err) => console.log(err.message))
    },
    typeChange() {
      this.selectValue = ''
      this.getList()
    },
  },
}
</script>

<style lang="scss" scoped>
.integral-type-select {
  display: inline-flex;

  > div:nth-child(1) {
    width: 43%;
  }

  > div:nth-child(2) {
    margin-left: 3%;
    width: 54%;
  }
}
</style>
